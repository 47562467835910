import * as React from 'react';

export default function Icon() {
	return (
		<svg className="mx-auto pinpoint" width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path d="M79.9072 12.3879C71.9189 4.39942 61.2976 0 50.0003 0C38.703 0 28.0815 4.39942 20.0932 12.3879C12.1047 20.3766 7.70532 30.9977 7.70532 42.2948C7.70532 65.1488 29.3143 84.1576 40.9235 94.3697C42.5368 95.7889 43.93 97.0145 45.04 98.0512C46.4306 99.3502 48.2156 99.9998 50.0001 99.9998C51.7851 99.9998 53.5697 99.3502 54.9605 98.0512C56.0705 97.0143 57.4636 95.7889 59.0769 94.3697C70.6861 84.1574 92.2951 65.1488 92.2951 42.2948C92.2949 30.9977 87.8957 20.3766 79.9072 12.3879ZM55.2078 89.9717C53.5591 91.4219 52.1355 92.6744 50.9617 93.7707C50.4224 94.274 49.5779 94.2742 49.0384 93.7707C47.8648 92.674 46.4409 91.4217 44.7923 89.9715C33.8782 80.3709 13.5628 62.4999 13.5628 42.295C13.5628 22.2036 29.9083 5.85802 49.9999 5.85802C70.0914 5.85802 86.4369 22.2036 86.4369 42.295C86.4371 62.4999 66.1218 80.3709 55.2078 89.9717Z" fill="#F37A54" />
			<path d="M50.0002 22.0582C39.7227 22.0582 31.3613 30.4194 31.3613 40.6969C31.3613 50.9745 39.7227 59.3357 50.0002 59.3357C60.2778 59.3357 68.6389 50.9745 68.6389 40.6969C68.6389 30.4194 60.2778 22.0582 50.0002 22.0582ZM50.0002 53.4776C42.9528 53.4776 37.2192 47.744 37.2192 40.6967C37.2192 33.6495 42.9528 27.9159 50.0002 27.9159C57.0477 27.9159 62.7811 33.6495 62.7811 40.6967C62.7811 47.744 57.0477 53.4776 50.0002 53.4776Z" fill="#F37A54" />
		</svg>
	);
}
