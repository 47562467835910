import * as React from 'react';
import { Link } from 'gatsby';

export default function Button({ children, to }) {
	return (
		<Link className="inline-block px-3 py-2 bg-primary text-white font-display text-base md:text-lg uppercase hover:bg-primary-dark transition duration-300" to={to}>
			{children}
		</Link>
	);
}
