import * as React from 'react'
import { Link } from 'gatsby'
import routes from '../common/routes'
import { useLocations } from '../hooks/useLocations'
import Button from './Button'
import Icon from './Icon'
import getLocationRoute from '../lib/routing/getLocationRoute'

export default function LocationsSection() {
  const locations = useLocations()

  return (
    <section className="py-8 md:py-16">
      <div className="container">
        <div className="flex flex-wrap ">
          <div className="flex w-full py-4 lg:w-1/3 md:py-16 lg:pr-16">
            <div className="w-full">
              <h2 className="mb-8">
                <div className="box-underline">Locations</div>
              </h2>
              <p>
                Wherever you are in Wisconsin, we have vetted reliable, reputable, quality
                foundation repair experts near you.
              </p>
              <Button to={routes.Locations}>View All Locations</Button>
            </div>
          </div>
          <div className="flex flex-wrap w-full -mx-2 text-center lg:w-2/3 ">
            {locations.slice(0, 8).map(({ node }) => (
              <div key={node.frontmatter.name} className="w-1/2 p-2 md:w-1/4 lg:w-1/4 ">
                <div className="">
                  <Link className="" to={getLocationRoute(node.frontmatter.name)}>
                    <Icon />
                  </Link>

                  <h3>
                    <Link
                      className="hover:text-primary-dark"
                      to={getLocationRoute(node.frontmatter.name)}
                    >
                      {node.frontmatter.name}
                    </Link>
                  </h3>
                  <p>{node.frontmatter.shortDescription}</p>
                  <Link
                    className="uppercase h4 text-primary hover:text-primary-dark"
                    to={getLocationRoute(node.frontmatter.name)}
                  >
                    {node.frontmatter.name} Services
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
