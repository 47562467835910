import * as React from "react";
import Layout from "../common/Layout";
import HeroBanner from "../components/HeroBanner";
import ServicesSection from "../components/ServicesSection";
import Callout from "../components/Callout";
import FormCallout from "../components/FormCallout";
import LocationsSection from "../components/LocationsSection";

export default function Index() {
  return (
    <Layout
      title="Wisconsin Foundation Repair"
      description="Serving Wisconsin's foundations needs. Contact us today to start the process of fixing your foundation."
    >
      <HeroBanner />
      <LocationsSection />
      <ServicesSection />
      <Callout />
      {/* <FormCallout /> */}
    </Layout>
  );
}
