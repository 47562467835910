import * as React from 'react'
import ButtonCall from './ButtonCall'

export default function Callout() {
  return (
    <section className="py-8 text-center md:py-16 bg-light-gray">
      <div className="container lg:max-w-2xl">
        <h2 className="mb-4 md:mb-8">CALL TODAY TO SCHEDULE</h2>
        <p>
          Get started on your foundation repair and call us today to speak to a representative. It
          is important to choose a foundation repair expert, and that's why we have done the vetting
          for you.
        </p>
        <ButtonCall />
      </div>
    </section>
  )
}
