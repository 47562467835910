import * as React from 'react'
import { Link } from 'gatsby'
import routes from '../common/routes'
import { useServices } from '../hooks/useServices'
import Button from './Button'
import getServiceRoute from '../lib/routing/getServiceRoute'

export default function ServicesSection() {
  const services = useServices()

  return (
    <section className="py-8 md:py-16">
      <div className="container">
        <div className="flex flex-wrap ">
          <div className="flex w-full py-4 lg:w-5/12 lg:order-2 md:py-16 lg:px-16 xl:pl-24">
            <div className="w-full">
              <h2 className="mb-8">
                <div className="box-underline">Services</div>
              </h2>
              <p>
                Here are a few of the top foundation repair services we see time and time again.
                Whether you need a professional to assess the state of your foundation, or you know
                what you need, these are the top requests we receive.
              </p>
              <Button to={routes.Services}>View All Services</Button>
            </div>
          </div>
          <div className="flex flex-wrap w-full -mx-2 text-center text-white lg:w-7/12 lg:order-1">
            {services.map(({ node }) => (
              <div key={node.frontmatter.name} className="w-1/2 p-2 xl:w-1/3 ">
                <Link to={getServiceRoute(node.frontmatter.name)}>
                  <div className="flex items-center h-full px-4 py-10 text-center bg-medium-gray hover:bg-primary-dark">
                    <h3 className="w-full">{node.frontmatter.name}</h3>
                  </div>
                </Link>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  )
}
