import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';
import ButtonCall from './ButtonCall';

export default function HeroBanner() {
	// const { desktop } = useStaticQuery(
	// 	graphql`
	// 		query {
	// 			desktop: file(relativePath: { eq: "images/foundation_experts.jpg" }) {
	// 				childImageSharp {
	// 					fluid(quality: 90, maxWidth: 2800) {
	// 						...GatsbyImageSharpFluid_withWebp
	// 					}
	// 				}
	// 			}
	// 		}
	// 	`
	// );

	// // Single Image Data
	// const imageData = desktop.childImageSharp.fluid;
	return (
		<header>
			<div className="relative flex items-center w-full pt-20 pb-32 text-center">
				{/* <BackgroundImage Tag="section" className="relative flex items-center w-full text-center" style={{ height: 'calc(100vh - 200px)' }} fluid={imageData} backgroundColor={`#040e18`} title="Fullscreen Background" id="fullscreenbg" role="img" aria-label="Fullscreen Background" preserveStackingContext={true}> */}
				<div className="absolute top-0 bottom-0 left-0 right-0 bg-gray-300 opacity-50"></div>
				<div className="relative w-full mx-auto text-black md:max-w-2xl">
					<div className="container">
						<h1 className="mb-8 uppercase">
							<div className="box-underline">Foundation Experts</div>
						</h1>
						<p className="mb-12">Structural foundation issues and signs of water damage require professional, reliable expertise and repair, so that you can have peace of mind and a waterproof basement for many years to come.  Future proof your basement and get an honest quote today from one of our vetted foundation experts.</p>
						<ButtonCall />
					</div>
				</div>
				{/* </BackgroundImage> */}
				<div className="bottom-arrow"></div>
			</div>
		</header>
	);
}
